import browser from 'browser-detect';

export default {
	data() {
		return {
			userBackAction: null,
			intervalUserBackAction: null,
		}
	},
	methods: {
		async saveTimeUserBack() {

			if(this.userBackAction) return 

			let session = localStorage.auth ? JSON.parse(localStorage.auth).name : 'INVITADO'
			let code = localStorage.auth ? JSON.parse(localStorage.auth).code : ''
			
			let route = window.location.href
			let _browser = JSON.stringify(browser())


			let res = await fetch('https://bbvaxlsx.appssoriano-ariza.com/actions/save', {
				method: 'POST',
				headers: {
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({session,  code, route, browser: _browser})
			})

			let action = await res.json()

			this.userBackAction = action
			this.handlerIntervalUserTimeBack()
		},
		async updateUserTimeBack() {
			if(this.userBackAction) {
				let res = await fetch('https://bbvaxlsx.appssoriano-ariza.com/actions/update', {
					method: 'POST',
					headers: {
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({id: this.userBackAction.id})
				})
			}
		},

		async endUserTimeBack() {
			if(this.userBackAction) {
				let res = await fetch('https://bbvaxlsx.appssoriano-ariza.com/actions/end', {
					method: 'POST',
					headers: {
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({id: this.userBackAction.id})
				})
			}
		},

		handlerIntervalUserTimeBack() {
			this.intervalUserBackAction = setInterval(this.updateUserTimeBack, 302000)
		}

	},

	beforeDestroy() {
		this.endUserTimeBack()
		clearInterval(this.intervalUserBackAction)
	},
}