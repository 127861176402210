<script>
export default {
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			
		}
	},
	// mounted() {
	// 	this.$tours['myTour'].start()
	// }
}
</script>
<template>
	<div class="s-main__nav ">
		<div class="s-main__nav-item px-3 pb-1">
			<div class="slds-icon-waffle_container">
				<div class="slds-icon-waffle"><div class="slds-r1"></div><div class="slds-r2"></div><div class="slds-r3"></div><div class="slds-r4"></div><div class="slds-r5"></div><div class="slds-r6"></div><div class="slds-r7"></div><div class="slds-r8"></div><div class="slds-r9"></div></div>
			</div>
			<span class="ml-3">Ventas BBVA</span>
		</div>
		<ul class="s-nav">
			<li>
				<router-link :to="{name: 'arce-index'}" class="s-nav__item" :class="{'s-nav__item--active': $route.name.includes('arce-index')}">Inicio</router-link>
			</li>
			<li>
				<router-link :to="{name: 'arce-clients'}" class="s-nav__item" :class="{'s-nav__item--active': $route.name.includes('client')}" data-v-step="0">Clientes
					<span class="s-nav__icon mt-1">
						<svg-icon name="chevrondown" />
					</span>
				</router-link> 
				
			</li>
			<li>
				<router-link :to="{name: 'arce-details-list'}" class="s-nav__item" :class="{'s-nav__item--active': $route.name.includes('details')}" data-v-step="0">ARCE </router-link> 
			</li>
			
			<li>
				<a href="#" class="s-nav__item">Oportunidades</a>
			</li>
			<li>
				<a href="#" class="s-nav__item">Alerta CRM</a>
			</li>
			<li>
				<a href="#" class="s-nav__item">Paneles</a>
			</li>
			<li>
				<a href="#" class="s-nav__item">Informes</a>
			</li>
			<li>
				<a href="#" class="s-nav__item">Metas de visitas</a>
			</li>
		</ul>
	</div>
</template>