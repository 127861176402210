<script>
import { mapGetters } from 'vuex'
import NavHeader from '../components/simulator/arce/NavHeader'
import StepsArce from '../components/simulator/arce/StepsArce'
import stepsMixin from '../mixins/stepsMixin'
import userActions from '@/mixins/actionsMixin'
import DraggableDiv from '../components/DraggableDiv.vue'
import {SCORM} from 'pipwerks-scorm-api-wrapper'

export default {
	mixins: [stepsMixin, userActions],
	components: {
		DraggableDiv,
		SvgIcon: () => import('../components/SvgIcon'),
		HeaderLayout: () => import('../components/simulator/arce/HeaderLayout'),
		NavHeader: NavHeader,
		StepsArce: StepsArce,
		ArceWizard: () => import('@/components/simulator/arce/ArceWizard'),
		ClientSearch: () => import('@/components/simulator/arce/ClientSearch'),
		GroupStructure: () => import('@/components/simulator/arce/GroupStructure')
	},
	data() {
		return {
			
			selectedFile: null,
			user: {
				id: '',
				name: '',
			},
				
		}
	},
	computed: {
		...mapGetters({
			showArceWizard: 'arceStatus/showArceWizard',
			showSearchClient: 'arceStatus/showSearchClient',
			arce: 'arceDetails/arce',
			filesArce: 'arceDetails/files',
		}),
		arceClientId() {
			// debugger
			return this.arce ? this.arce.groupId : 0
		}
	},
	methods: {
		showFilesPF() {
			document.getElementById('filesRespoPF').classList.toggle('active')
			let $drag = document.querySelector('#filesRespoPF .draggable-container')
			$drag.style.top = 0
			$drag.style.left = 0
		},
		selectFilePF(file) {
			this.$set(this, 'selectedFile', file)
			let $pdf = document.getElementById('filereader')
			document.getElementById('filesRespoPF').classList.add('read-mode')
			if(document.getElementById('btnFiles').dataset.show) {
				document.getElementById('filesRespoPF').classList.add('simulador-open')
			}
			// $pdf.window.eval('document.addEventListener("contextmenu", function (e) {e.preventDefault();}, false)')
		},
		closeFilesPF() {
			document.getElementById('filesRespoPF').classList.remove('active')
			let $drag = document.querySelector('#filesRespoPF .draggable-container')
			$drag.style.top = 0
			$drag.style.left = 0
		},
	},
	mounted() {
		SCORM.init();
		this.user.name = SCORM.get('cmi.core.student_name')
		this.user.id = SCORM.get('cmi.core.student_id')
	}
}
</script>
<template>
	<div class="s-wrap" style="font-size: 0.9em" @click="saveTimeUserBack()">
		
		<HeaderLayout />
		<main class="s-main">
			<div class="s-main__nav " data-v-step="0">
		<div class="s-main__nav-item px-3 pb-1" >
			<div class="slds-icon-waffle_container">
				<div class="slds-icon-waffle"><div class="slds-r1"></div><div class="slds-r2"></div><div class="slds-r3"></div><div class="slds-r4"></div><div class="slds-r5"></div><div class="slds-r6"></div><div class="slds-r7"></div><div class="slds-r8"></div><div class="slds-r9"></div></div>
			</div>
			<span class="ml-3">Global Desktop</span>
		</div>
		<ul class="s-nav" >
			<li>
				<router-link :to="{name: 'ArceHome'}" :class="{'s-nav__item--active': $route.name.includes('ArceHome')}" class="s-nav__item">Home</router-link>
			</li>
			<li>
				<router-link :to="{name: 'arce-clients'}" class="s-nav__item" data-v-step="1">Clients
					<span class="s-nav__icon mt-1">
						<svg-icon name="chevrondown" />
					</span>
				</router-link> 
			</li>
			<li>
				<router-link :to="{name: 'arce-index'}" class="s-nav__item" >Chatter</router-link>
			</li>
			<li>
				<router-link :to="{name: 'arce-clients'}" class="s-nav__item" data-v-step="0">Contacts
					<span class="s-nav__icon mt-1">
						<svg-icon name="chevrondown" />
					</span>
				</router-link> 				
			</li>
			<li>
				<router-link :to="{name: 'arce-details-list'}" class="s-nav__item" data-v-step="0">E-Learnning
					<span class="s-nav__icon mt-1">
						<svg-icon name="chevrondown" />
					</span>
				</router-link> 
			</li>
			<li>
				<router-link :to="{name: 'ArceList'}" class="s-nav__item" :class="{'s-nav__item--active': $route.name.includes('ArceList')}" data-v-step="0">ARCE 
					<span class="s-nav__icon mt-1">
						<svg-icon name="chevrondown" />
					</span>
				</router-link> 
			</li>
			<li>
				<router-link :to="{name: 'arce-clients'}" :class="{'s-nav__item--active': $route.name.includes('client')}" class="s-nav__item" data-v-step="0">TUM TRANSPORTISTAS UNIDOS M...</router-link> 
			</li>
			
			
		</ul>
	</div>
		<div class="s-main__content">
			<div class="s-main__wrap">
				<router-view></router-view>
			</div>
		</div>
		</main>
		<ClientSearch v-if="showSearchClient"  />
		<ArceWizard v-if="showArceWizard" />
		<!-- <ArceWizard /> -->
		<GroupStructure v-if="arce" :arce="arce"/>

		<StepsArce />

		<notifications position="top center" width="500px"  group="simulator">
			<template slot="body" slot-scope="props">
				<div class="s-notification" :class="props.item.type">
					<div class="p-2 s-notification__icon">
						<i v-if="props.item.type == 'success'" class="fa fa-check-circle" aria-hidden="true"></i>
						<i v-if="props.item.type == 'warn'" class="fa fa-exclamation-triangle"></i>
					</div>
					<div class="flex-grow-1">
						<p class="title m-0">
						{{props.item.title}}
						</p>
						<div class="m-0" v-html="props.item.text">
						</div>
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-fw fa-close"></i>
					</a>
					
				</div>
  			</template>
		</notifications>

		<div v-if="arceClientId" id="filesRespoPF" class="files-repo"  >
			<DraggableDiv class="files-repo__drag">
				<template slot="header">
					<div id="filesRespoPFheader" class="files-repo__header py-2 px-3 s">
						<h6 class="m-0">Expediente {{filesArce.name}}</h6> <button class="files-repo__close" @click="closeFilesPF"><i class="fa fa-times" aria-hidden="true"></i></button>
					</div>
				</template>
				<template slot="main">
					<div class="files-repo__body position-relative h-100 flex-grow-1">
						<div v-if="!selectedFile">
							<div v-if="filesArce.files.buros.length" class="mb-3">
								<h6>Buros</h6>
								<ul class="d-flex flex-wrap files-list">
									<li class="files-list__item col-6 p-1" v-for="(file, index) in filesArce.files.buros" :key="`file_${index}`" >
										<div class="files-list__item-name d-flex" @click="selectFilePF(file)">
											<span class="pr-1 my-auto">
												<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
											</span>
											<span>
											{{ file.name }}
											</span>
										</div>
									</li>
								</ul>
							</div>
							<div v-if="filesArce.files.eeff.length" class="mb-3">
								<h6>EEFF SINTESIS FINANCIERA CREDITLENS/ARCE CORPORATE​</h6>
								<ul class="d-flex flex-wrap files-list">
									<li class="files-list__item col-6 p-1" v-for="(file, index) in filesArce.files.eeff" :key="`file_${index}`" >
										<div class="files-list__item-name d-flex" @click="selectFilePF(file)">
											<span class="pr-1 my-auto">
												<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
											</span>
											<span>
											{{ file.name }}
											</span>
										</div>
									</li>
								</ul>
							</div>
							<div v-if="filesArce.files.general.length" class="mb-3">
								<h6>General</h6>
								<ul class="d-flex flex-wrap files-list">
									<li class="files-list__item col-6 p-1" v-for="(file, index) in filesArce.files.general" :key="`file_${index}`" >
										<div class="files-list__item-name d-flex" @click="selectFilePF(file)">
											<span class="pr-1 my-auto">
												<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
											</span>
											<span>
											{{ file.name }}
											</span>
										</div>
									</li>
								</ul>
							</div>
							<div v-if="filesArce.files.ra.length" class="mb-3">
								<h6>Creditlens/ARCE Corporate​</h6>
								<ul class="d-flex flex-wrap files-list">
									<li class="files-list__item col-6 p-1" v-for="(file, index) in filesArce.files.ra" :key="`file_${index}`" >
										<div class="files-list__item-name d-flex" @click="selectFilePF(file)">
											<span class="pr-1 my-auto">
												<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
											</span>
											<span>
											{{ file.name }}
											</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="position-absolute w-100 h-100 d-flex"  v-else>
							<div class="col-2 p-0 h-100 overflow-auto">
								<div v-if="filesArce.files.buros.length" class="mb-3">
									<h6>Buros</h6>
									<ul class="d-flex flex-wrap files-list">
										<li class="files-list__item col-12 p-1" v-for="(file, index) in filesArce.files.buros" :key="`file_${index}`" >
											<div class="files-list__item-name d-flex" @click="selectFilePF(file)">
												<span class="pr-1 my-auto">
													<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
												</span>
												<span>
												{{ file.name }}
												</span>
											</div>
										</li>
									</ul>
								</div>
								<div v-if="filesArce.files.eeff.length" class="mb-3">
									<h6>EEFF</h6>
									<ul class="d-flex flex-wrap files-list">
										<li class="files-list__item col-12 p-1" v-for="(file, index) in filesArce.files.eeff" :key="`file_${index}`" >
											<div class="files-list__item-name d-flex" @click="selectFilePF(file)">
												<span class="pr-1 my-auto">
													<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
												</span>
												<span>
												{{ file.name }}
												</span>
											</div>
										</li>
									</ul>
								</div>
								<div v-if="filesArce.files.general.length" class="mb-3">
									<h6>General</h6>
									<ul class="d-flex flex-wrap files-list">
										<li class="files-list__item col-12 p-1" v-for="(file, index) in filesArce.files.general" :key="`file_${index}`" >
											<div class="files-list__item-name d-flex" @click="selectFilePF(file)">
												<span class="pr-1 my-auto">
													<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
												</span>
												<span>
												{{ file.name }}
												</span>
											</div>
										</li>
									</ul>
								</div>
								<div v-if="filesArce.files.ra.length" class="mb-3">
									<h6>RA</h6>
									<ul class="d-flex flex-wrap files-list">
										<li class="files-list__item col-12 p-1" v-for="(file, index) in filesArce.files.ra" :key="`file_${index}`" >
											<div class="files-list__item-name d-flex" @click="selectFilePF(file)">
												<span class="pr-1 my-auto">
													<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
												</span>
												<span>
												{{ file.name }}
												</span>
											</div>
										</li>
									</ul>
								</div>
								<!-- <ul class="d-flex flex-wrap files-list">
									<li class="files-list__item col-12 p-1" v-for="(file, index) in files" :key="`file_${index}`">
										<div class="files-list__item-name d-flex" :class="{'selected': file.src == selectedFile.src}"  @click="selectFile(file)">
											<span class="pr-1 my-auto">
												<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
											</span>
											<span>
											{{ file.name }}
											</span>
										</div>
									</li>
								</ul> -->
							</div>
							<iframe id="filereader" class="col-10 p-0 h-100" :src="`${filesArce.base_path}${selectedFile.src}#toolbar=0`" type="">
							</iframe>
						</div>
					</div>
				</template>
				<template slot="footer">
				</template>
			</DraggableDiv>
		</div>
		<button v-if="arceClientId" id="btnFiles" @click="showFilesPF" class="btn-files"><i class="fa fa-folder" aria-hidden="true" ></i>
			<span class="btn-files__label">Expediente {{filesArce.name}}</span>
		</button>

		<!-- <footer class="s-footer"></footer>
		 -->
		<!-- <svg lightning-primitiveIcon_primitiveIcon="" focusable="false" data-key="down" aria-hidden="true" class="slds-icon slds-icon-text-default slds-icon_xx-small"><use lightning-primitiveIcon_primitiveIcon="" xlink:href="../assets/simulator/symbols.svg?cache=9.36.1#down"></use></svg> -->
	</div>
</template>
<style lang="scss">
@import '../assets/scss/simulator/index.scss';
.btn-files{
	position: fixed;
    bottom: 0.25rem;
    left: 3vw;
    left: 3%;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    background-color: #2DCCCD;
    color: #fff;
    z-index: 100;
    border-radius: 50%;
    border: 0 none;
	box-shadow: 0 0 20px #2DCCCD4f;
	transition: 0.3s;
	outline: none !important;
	&.simulador-open{
		bottom: 3.5rem;
		right: 1rem;
		.btn-files{
			&__label{
				transform: translateY(-50%) scaleX(0);
				opacity: 0;
			}
		}
		&:hover{
			.btn-files{
				&__label{
					transform: translateY(-50%) scaleX(1);
					opacity: 1;
				}
			}
		}
	}
	&__label{
		background-color: #2DCCCD;
		color: #fff;
		display: inline-block;
		position: absolute;
		padding: 0.1rem 1rem;
		white-space: nowrap;
		left: 90%;
		font-size: 0.8em;
		border-radius: 0 30px 30px 0;
		top: 50%;
		transform: translateY(-50%);
		transition: 0.3s;
		transform-origin: left center;
	}
}
.files-list{
	list-style: none;
	padding: 0;
	margin: 0;
	&__item{
		padding: 0.5em;
		font-size: 0.65em;
		&-name{
			border: 1px solid rgb(192, 192, 192);
			border-radius: 0.25rem;
			padding: 0.5rem;
			cursor: pointer;
			transition: 0.3s;
			&.selected{
				border-color: #6d6d6d;
				background-color: #eee;
			}
			&:hover{
				box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
				border-color: #6d6d6d;
			}
			.fa{
				color: crimson;
				font-size: 1.5em;
			}
		}
	}
}
.files-repo{
	width: 1px;
	min-width: 1px;
	position: fixed;
	height: 400px;
	bottom: 56px;
	background: transparent;
	left: -1300px;
	opacity: 0;
	transition: 0.3s;
	display: flex;
	flex-direction: column;
	transform: translateX(-100%);
	z-index: 99;
	&__drag{
		width: 90vw;
		min-width: 90%;
		height: 100%;
		box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.3);
	}
	@media screen and (min-width: 800px) {
		&__drag{
			width: 30vw;
			min-width: 30%;
		}
	}
	&.simulador-open{
		bottom: 35px;

	}
	&.read-mode{
		.files-repo{
			&__drag{
				width: 60vw;
			}
		}
	}
	&.active{

		opacity: 1;
		left: 5vw;
		left: 5%;
		transform: translateX(0%);

	}
	&__close{
		position: absolute;
		color: #ffff;
		background-color: transparent;
		border: 0 none;
		outline: none !important;
		right: 0.5rem;
		top: 50%;
		transform: translateY(-50%);
	}

	
	
	&__header{
		background-color: #022857;
		background-image: url('../assets/img/bg_menu.jpg');
		background-position: bottom left;
		background-size: cover;
		color: #fff;
		position: relative;
	}
	&__body{
		border-left: 10px solid #022857;
		border-right: 10px solid #022857;
		border-bottom: 10px solid #022857;
		flex-grow: 1;
		height: 50%;
		background-color: #fff;
		overflow: auto;
	}
}
</style>
<style lang="scss" scoped>
</style>