var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-main__nav"},[_vm._m(0),_c('ul',{staticClass:"s-nav"},[_c('li',[_c('router-link',{staticClass:"s-nav__item",class:{'s-nav__item--active': _vm.$route.name.includes('arce-index')},attrs:{"to":{name: 'arce-index'}}},[_vm._v("Inicio")])],1),_c('li',[_c('router-link',{staticClass:"s-nav__item",class:{'s-nav__item--active': _vm.$route.name.includes('client')},attrs:{"to":{name: 'arce-clients'},"data-v-step":"0"}},[_vm._v("Clientes "),_c('span',{staticClass:"s-nav__icon mt-1"},[_c('svg-icon',{attrs:{"name":"chevrondown"}})],1)])],1),_c('li',[_c('router-link',{staticClass:"s-nav__item",class:{'s-nav__item--active': _vm.$route.name.includes('details')},attrs:{"to":{name: 'arce-details-list'},"data-v-step":"0"}},[_vm._v("ARCE ")])],1),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-main__nav-item px-3 pb-1"},[_c('div',{staticClass:"slds-icon-waffle_container"},[_c('div',{staticClass:"slds-icon-waffle"},[_c('div',{staticClass:"slds-r1"}),_c('div',{staticClass:"slds-r2"}),_c('div',{staticClass:"slds-r3"}),_c('div',{staticClass:"slds-r4"}),_c('div',{staticClass:"slds-r5"}),_c('div',{staticClass:"slds-r6"}),_c('div',{staticClass:"slds-r7"}),_c('div',{staticClass:"slds-r8"}),_c('div',{staticClass:"slds-r9"})])]),_c('span',{staticClass:"ml-3"},[_vm._v("Ventas BBVA")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"s-nav__item",attrs:{"href":"#"}},[_vm._v("Oportunidades")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"s-nav__item",attrs:{"href":"#"}},[_vm._v("Alerta CRM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"s-nav__item",attrs:{"href":"#"}},[_vm._v("Paneles")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"s-nav__item",attrs:{"href":"#"}},[_vm._v("Informes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"s-nav__item",attrs:{"href":"#"}},[_vm._v("Metas de visitas")])])
}]

export { render, staticRenderFns }