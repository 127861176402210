<template>
  <div ref="draggableContainer" class="draggable-container">
    <div id="draggable-header" @mousedown="dragMouseDown">
      <slot name="header"></slot>
    </div>
	<div id="draggable-inner-overlay">

	</div>
    <slot name="main"></slot>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: 'DraggableDiv',
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  methods: {
    dragMouseDown: function (event) {
		event.preventDefault()
		// get the mouse cursor position at startup:
		this.$el.querySelector('#draggable-inner-overlay').style.display = 'block'

		this.positions.clientX = event.clientX
		this.positions.clientY = event.clientY
		document.onmousemove = this.elementDrag
		document.onmouseup = this.closeDragElement

		let $overlay = document.querySelector('#dragable-overlay')
		$overlay.style.display = 'block'

    },
    elementDrag: function (event) {
		event.preventDefault()
		this.positions.movementX = this.positions.clientX - event.clientX
		this.positions.movementY = this.positions.clientY - event.clientY
		this.positions.clientX = event.clientX
		this.positions.clientY = event.clientY
		// set the element's new position:
		this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
		this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement () {
    	document.onmouseup = null
    	document.onmousemove = null
		this.$el.querySelector('#draggable-inner-overlay').style.display = 'none'
	   	let $overlay = document.querySelector('#dragable-overlay')
		$overlay.style.display = 'none'
    }
  },

  mounted() {
	  let $overlay = document.querySelector('#dragable-overlay')


	  if(!$overlay) {
		let $overlayInner = document.createElement('div')
		$overlayInner.setAttribute('id', 'dragable-overlay')
		document.body.appendChild($overlayInner)
	  } 
  }
}
</script>

<style>
.draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
  position: relative;
}

#draggable-inner-overlay{
	position: absolute;
	z-index: 9;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	display: none;
}
</style>