<script>
export default {
	data() {
		return {
			steps:  [
				{
					target: '[data-v-step="0"]',  // We're using document.querySelector() under the hood
					header: {
						title: 'Selección de clientes',
					},
					params: {
						enableScrolling: false
					},
					content: `El acceso a clientes puede realizarse de distintas formas`
				},
				{
					target: '[data-v-step="1"]',  // We're using document.querySelector() under the hood
					header: {
						title: 'Selección de clientes',
					},
					params: {
						enableScrolling: false
					},
					content: `Desde el menú de clientes en caso de haberlo abierto anteriormente`
				},
				{
					target: '[data-v-step="searchClient"]',  // We're using document.querySelector() under the hood
					header: {
						title: 'Selección de clientes',
					},
					params: {
						enableScrolling: false
					},
					content: `Desde la página principal en el buscador de la parte superior de la pantalla`
				},
				{
					target: '[data-v-step="searchClient"]',  // We're using document.querySelector() under the hood
					header: {
						title: 'Selección de clientes',
					},
					params: {
						enableScrolling: false
					},
					content: `Puedes buscar por nombre, también por CIF, código Alpha o Local Client`,
					before: () =>
					new Promise(async (resolve) => {
						let vm = this
						vm.eventBusEmit('searchClient', 'PROPYSOL, SA DE CV')
						// await this.$refs.view?.fakeVote();
						// this.loadStep(3);
						resolve(true);
					}),
				},
				{
					target: '[data-v-step="resultSearch"]',  // We're using document.querySelector() under the hood
					header: {
						title: 'Selección de clientes',
					},
					params: {
						enableScrolling: false,
						enabledButtons: {
							buttonSkip: false,
							buttonPrevious: false,
							buttonNext: false,
							buttonStop: false
						},
					},
					content: `Coincidencia de varios clientes con el mismo nombre en el buscador <br> Haz clic en el cliente`,
					before: () =>
					new Promise(async (resolve) => {
						let vm = this
						vm.eventBusEmit('loadSearchClient')
						setTimeout(() => resolve(true), 1300)
						// await this.$refs.view?.fakeVote();
						// this.loadStep(3);
						// resolve(true);
					}),
				}
			],
			myOptions: {
				highlight: true,
				useKeyboardNavigation: true,
				labels: {
					buttonSkip: 'Saltar',
					buttonPrevious: 'Anterior',
					buttonNext: 'Siguiente',
					buttonStop: 'Terminar'
				}
			},

			

			stepsControls: [
				{
					target: '[data-v-step="sctr1"]',  // We're using document.querySelector() under the hood
					content: `<div class="text-left">Procede con la validación del cliente, una vez que tengas el 100% de avance en cada ficha, y hayas agregado las Políticas para este Cromo.</div>`,
					header: {
						title: 'Validación de cliente',
					},
					params: {
						placement: 'top',
						enableScrolling: true
					},
					offset: -200
				},
			]
		}
	}
}
</script>
<template>
	<div>
		<!-- <div class="tour-block"></div> -->
		<v-tour name="myTour" :steps="steps" :options="myOptions"></v-tour>
	</div>
</template>
<style lang="scss" scoped>
.tour-block{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: red;
	z-index: 100;
}
::v-deep {
	.v-step{
		background-color: #0070D2;
	}
	.v-step__header{
		background-color: #045397;
	}
	.v-step .v-step__arrow{
		border-color: #0070D2;
	}
	.v-step[x-placement^=bottom] .v-step__arrow{
		border-width: 0 .75rem 1.5rem .75rem;
		top: -1.5rem;
	}

	.v-step[x-placement^=right] .v-step__arrow{
		border-width: .75rem  1.5rem .75rem 0 ;
		left: -1.5rem;
	}
	.v-step[x-placement^=left] .v-step__arrow{
		border-width: .75rem 0 .75rem  1.5rem;
		right: -1.5rem;
	}
	.v-step[x-placement^=top] .v-step__arrow{
		border-width: 1.5rem .75rem 0 .75rem;
		bottom: -1.5rem;
	}

	.v-step[x-placement^=bottom] {
		margin-top: 1.5rem;
	}
	.v-step[x-placement^=top] {
		margin-bottom: 1.5rem;
	}
	.v-step[x-placement^=left] {
		margin-right: 1.5rem;
	}
	.v-step[x-placement^=right] {
		margin-left: 1.5rem;
	}
}
</style>